{
  "button": {
    "back": "Zurück",
    "cancel": "Abbrechen",
    "confirm": "Bestätigen Sie",
    "fullscreen_enter": "Vollbildmodus aktivieren",
    "fullscreen_exit": "Vollbild beenden",
    "refresh": "Aktualisierung",
    "toggle_dark": "Dunkelmodus umschalten",
    "toggle_langs": "Sprachen ändern",
    "remove": "Entfernen",
    "clear_all": "Alles löschen",
    "delete": "Löschen",
    "edit": "Bearbeiten",
    "buy_now": "Kaufe jetzt",
    "enter_another_card": "Geben Sie eine andere Karte ein",
    "try_again": "Versuch es noch einmal",
    "enter_another_code": "Geben sie einen anderen Code ein",
    "submit_code": "Code einreichen",
    "download": "Herunterladen",
    "no": "Nein",
    "go_to_homepage": "Gehen Sie zur Startseite",
    "slide_back": "Vorherige Folie",
    "slide_forward": "Nächste Folie",
    "exit": "Ausfahrt",
    "full": "Voll",
    "regular": "Regulär",
    "rename": "Umbenennen",
    "get": "Erhalten"
  },
  "common": {
    "loading": "Wird geladen...",
    "loading_failed": "Laden fehlgeschlagen",
    "empty_search": "Keine Ergebnisse mit dem Titel „{searchValue}“ gefunden.",
    "empty_list": "Die Liste ist leer.\nKomme später wieder.",
    "data_not_found": "Daten nicht gefunden oder Server antwortet nicht. Bitte versuchen Sie es später erneut.",
    "empty_events_list": "Es gibt noch keine Veranstaltungen",
    "get_mobile_app": "Holen Sie sich die beste Erfahrung \n{appName}"
  },
  "not-found": "Nicht gefunden",
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "pt": "Portuguese",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "Wählen Sie die Sprache der Website",
    "ca": "Catalan"
  },
  "baseHeader": {
    "menu": {
      "account": "Ihr Konto",
      "language": "Sprache: {currentLanguage}",
      "logout": "Ausloggen",
      "manage_following": "Folgen verwalten",
      "select_language": "Sprache auswählen",
      "selected_videos": "Ausgewählte Videos",
      "my_clips_and_highlights": "Meine Clips/Highlights"
    }
  },
  "labels": {
    "sign_in": "Einloggen",
    "sign_up": "Anmeldung",
    "continue": "Fortsetzen",
    "email": "Email",
    "forgot_password": "Passwort vergessen",
    "password": "Passwort",
    "date_added": "Datum hinzugefügt:",
    "highlights": "Höhepunkt | Höhepunkte",
    "latest": "Neueste",
    "live": "Live",
    "password_new": "Neues Passwort",
    "password_old": "Altes Passwort",
    "password_repeat": "Passwort wiederholen",
    "save": "Speichern",
    "see_all": "Alle anzeigen >",
    "upcoming": "Bevorstehende",
    "update": "Aktualisieren",
    "country": "Land",
    "phone": "Telefon",
    "search": "Suche",
    "load_more": "Mehr laden",
    "follow": "Folgen",
    "following": "Folgend",
    "total": "Gesamt",
    "all": "Alle",
    "sport_type": "Sporttyp",
    "full_name": "Vollständiger Name",
    "newest": "Neueste",
    "oldest": "Älteste",
    "modal_labels": {
      "delete_clip": "Möchten Sie diesen Clip wirklich entfernen?",
      "delete_highlight": "Möchten Sie diese Markierung wirklich entfernen?",
      "delete_profile": "Möchten Sie Ihr Profil wirklich löschen?",
      "delete_tag": "Möchten Sie diese Tags wirklich entfernen? |\nMöchten Sie dieses Tag wirklich entfernen?"
    },
    "active": "Aktiv",
    "submit": "Einreichen",
    "purchase": "Kaufen",
    "confirm_new_password": "Bestätige neues Passwort",
    "enter_new_password": "Neues Passwort eingeben",
    "dark": "Dunkel",
    "light": "Licht",
    "powered_by": "Unterstützt von",
    "policy": "Datenschutz-Bestimmungen",
    "terms": "Nutzungsbedingungen",
    "next": "Nächste",
    "search_input_placeholder": "Suche Schulen, Vereine, Ligen etc.",
    "skip": "Überspringen",
    "amount": "Menge",
    "date": "Datum",
    "type": "Typ",
    "continue_with": "Weiter mit {Anbieter}",
    "or": "oder",
    "anytime": "Jederzeit wieder",
    "event_date": "Veranstaltungsdatum",
    "past_30_days": "Letzte 30 Tage",
    "past_7_days": "Vergangene 7 Tage",
    "past_90_days": "Vergangene 90 Tage",
    "today": "Heute",
    "yesterday": "Gestern",
    "all_results": "Alle Ergebnisse",
    "events": "Veranstaltungen",
    "views": "keine Aufrufe |\n{count} anzeigen |\n{count} Aufrufe",
    "clip": "Klammer |\nKlammern",
    "tag": "Tag |\nStichworte",
    "game_videos": "Spielvideos",
    "bookmark_add": "Zu ausgewählten hinzufügen",
    "bookmark_remove": "Von ausgewählten entfernen",
    "share": "Teilen",
    "days": "Tage | \ntag | \ntage",
    "hours": "Std | \nstunde | \nstd",
    "minutes": "Protokoll | \nminute | \nprotokoll",
    "seconds": "Sekunden | \nzweite | \nsekunden",
    "latest_events": "Letzte Veranstaltungen",
    "latest_highlights": "Neueste Höhepunkte",
    "statistics": "Statistiken",
    "commerce_category_labels": {
      "free": "Frei",
      "member": "Mitglied",
      "ppv": "Pay-per-View",
      "subscription": "Abonnement",
      "rental": "Vermietung",
      "premium": "Prämie"
    },
    "lock_event_message": {
      "member": "Bitte melden Sie sich an, um das Spiel anzusehen",
      "geo_blocking": "Es tut uns leid, aber das Video, das Sie abspielen möchten, ist in Ihrer Region eingeschränkt",
      "event_restricted": "Diese Veranstaltung ist für Ihren Standort eingeschränkt",
      "premium": "Abonnieren Sie Watch",
      "subscription": ""
    },
    "main": "Hauptsächlich",
    "game_highlights": "Höhepunkte des Spiels",
    "player_highlights": "Spieler-Highlights",
    "watch_full_game": "Ganzes Spiel ansehen",
    "your_clips_highlight": "Ihre Clips/Highlights",
    "create_highlight": "Highlights setzen",
    "access_code": "Geben Sie den Zugangscode ein",
    "ppv_price": "Ab {price} ansehen",
    "access_time": "Sie erhalten Zugang zu",
    "full_access": "Voller Zugriff",
    "limited_access": "Eingeschränkter Zugang",
    "ppv_tickets": "PPV-Tickets",
    "subscriptions": "Abonnements",
    "release_date": "Veröffentlichungsdatum",
    "months": "Monate | \nmonat | \nmonate",
    "weeks": "Wochen | \nwoche | \nwochen",
    "years": "Jahre | \njahr | \njahre",
    "change_product": "Produkt wechseln",
    "go_to_event": "Gehen Sie zur Veranstaltung",
    "go_to_account": "Gehen Sie zum Konto",
    "go_to_main_page": "Gehen Sie zur Hauptseite",
    "name": "Name",
    "update_card": "Karte aktualisieren",
    "page_not_found": "Seite nicht gefunden",
    "inactive": "Inaktiv",
    "my_club": "Mein Club",
    "select_your_club": "Wählen Sie Ihren Verein aus",
    "add_to_selected": "Zu Ausgewählt hinzufügen",
    "remove_from_selected": "Aus Ausgewählt entfernen",
    "password_not_match": "Passwörter stimmen nicht überein",
    "search_by_sport_type": "Suche nach Sportart",
    "videos": "Videos",
    "category": "Kategorie",
    "reset_password": "Bitte geben Sie Ihr neues Passwort ein",
    "min_8_characters": "Mindestens 8 Zeichen",
    "league": "Liga",
    "here": "Hier",
    "suggested": "Empfohlen",
    "enter_old_password": "Geben Sie das alte Passwort ein",
    "less": "Weniger",
    "more": "Mehr",
    "durationRenew": "Dauer: wird nach dem Kauf um {duration} {units} verlängert | \nDauer: wird am {duration} verlängert",
    "duration": "Dauer: läuft {duration} {units} nach dem Kauf ab | \nDauer: läuft ab: {duration}",
    "team": "Team",
    "team_1_logo": "Team 1-Logo",
    "team_2_logo": "Team 2-Logo",
    "stats": "Statistiken",
    "gameType_labels": {
      "game": "Spiel",
      "other": "Andere",
      "practice": "Üben",
      "training": "ausbildung"
    },
    "fullName": "Vollständiger Name",
    "archive": "Archiv",
    "panoramic_mode": "Sie befinden sich im Panoramamodus",
    "postal_code": "Postleitzahl",
    "search_event_league_team": "Suchen Sie nach einem Event, einer Liga oder einem Team",
    "back_to_site": "Zurück zur Website",
    "done": "Erledigt",
    "search_teams": "Suchteams",
    "game_recap": "Spielrückblick",
    "play_by_play": "Spiel für Spiel",
    "your_clips": "Deine Clips"
  },
  "pages": {
    "auth_sign_up": {
      "enter_your_details_below": "Geben Sie unten Ihre Daten ein",
      "password_updated_successuly_text": "Ihr Passwort wurde erfolgreich aktualisiert",
      "already_have_account": "Sie haben bereits ein Konto?",
      "follow_category_header": "Wählen Sie die Kategorien aus, denen Sie folgen möchten",
      "back_to_all_teams": "Zurück zu allen Teams",
      "follow_teams_header": "Wählen Sie Teams aus, denen Sie folgen möchten",
      "back_to_all_categories": "Zurück zu allen Kategorien"
    },
    "account": {
      "account_delete_button": "Konto löschen",
      "tab_billing_title": "Abrechnungsdaten",
      "tab_devices_title": "Geräte",
      "tab_general_title": "Allgemein",
      "tab_password_title": "Passwort",
      "tab_subscription_title": "Abonnement",
      "account_delete_confirm": "Möchten Sie Ihr Profil wirklich löschen?",
      "account_delete_description": "Diese Aktion kann nicht rückgängig gemacht werden.",
      "tab_ppv_access_text": "Das PPV-Ticket ermöglicht den Zugang zu",
      "tab_ppv_no_ppv": "Sie haben keine PPV-Tickets. \nBitte besuchen Sie die Veranstaltungsseite, um ein Ticket zu kaufen.",
      "tab_ppv_title": "PPV-Tickets",
      "account_delete_successfully": "Ihr Konto wurde erfolgreich gelöscht!",
      "account_update_successfully": "Ihr Konto wurde erfolgreich aktualisiert!"
    },
    "auth_password": {
      "forgot_password_help_text": "Wir helfen Ihnen, es zurückzusetzen und wieder auf Kurs zu kommen.",
      "back_to_sign_in": "Zurück zum Anmelden",
      "send_reset_link": "Zurücksetzen-Link senden",
      "set_new_password": "Neues Passwort festlegen",
      "forgot_password_success_text": "Eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts wurde an {email} gesendet. \nWenn Sie die E-Mail nicht innerhalb weniger Minuten erhalten, klicken Sie unten auf die Schaltfläche „Anweisungen erneut senden“.",
      "resend_instruction": "Anweisungen erneut senden"
    },
    "clubs": {
      "manage_following": "Folgen verwalten",
      "choose_your_club": "Wählen Sie Ihren Verein",
      "choose_your_club_message": "Wählen Sie den Club aus, dem Sie zugeordnet werden"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "Ihr aktuelles Gerät befindet sich nicht mehr in der Liste der zulässigen Geräte. Bitte melden Sie sich erneut an."
    },
    "auth_login_limit": {
      "device_limit_reached": "Gerätelimit erreicht",
      "remove_device_to_login": "Bitte entfernen Sie eines, um sich anzumelden",
      "current_device": "Aktuelles Gerät"
    },
    "account_billing": {
      "card_cvv": "CVC / CVV",
      "card_expiry_date": "Verfallsdatum",
      "card_holder_name": "Name auf der Karte",
      "card_number": "Kartennummer",
      "button_back_to_billing_info": "Zurück zu den Rechnungsinformationen",
      "payment_history_empty_text": "Ihr Zahlungsverlauf ist leer",
      "payment_history_title": "Zahlungshistorie",
      "alert_credit_card_save_success": "Ihre Kreditkarteninformationen wurden erfolgreich gespeichert",
      "alert_credit_card_update_success": "Ihre Kreditkarteninformationen wurden erfolgreich aktualisiert",
      "label_modal_confirm_credit_card_delete": "Sobald Sie Ihre Kreditkarte entfernen, wird auch Ihr Abonnement gekündigt. \nDie Inhalte stehen Ihnen bis zum Enddatum des Plans weiterhin zur Verfügung. \nMöchten Sie die Kreditkarte wirklich entfernen?",
      "alert_credit_card_delete_success": "Ihre Rechnungskarte wurde erfolgreich gelöscht",
      "payment_in_progress": "Zahlung läuft",
      "payment_in_progress_message": "Bitte schließen Sie diese Seite nicht, bis Ihre Zahlung verarbeitet wurde."
    },
    "account_subscription": {
      "active_subscription": "Aktives Abonnement",
      "button_subscribe_now": "Abonniere jetzt",
      "button_subscription_cancel": "Abonnement kündigen",
      "subscription_due_date": "Geburtstermin",
      "subscription_expiration_date": "Abgelaufen von",
      "subscription_last_payment_date": "Letztes Zahlungsdatum",
      "subscription_next_charge_date": "Nächste Ladung",
      "button_back_to_my_subscription": "Zurück zu meinem Abo",
      "or_select_plan": "oder wählen Sie Ihren Plan aus",
      "redeem_access_code_label": "Lösen Sie hier Ihren Zugangscode ein",
      "alert_access_code_redeem_not_found": "Angeforderter Zugangscode existiert nicht",
      "alert_access_code_redeemed_success": "Zugangscode erfolgreich eingelöst",
      "alert_subscription_cancel_success": "Abonnement erfolgreich gekündigt",
      "label_plan_amount": "Betrag planen",
      "label_taxes": "Steuern",
      "label_total_billed": "Gesamt in Rechnung gestellt",
      "tab_all_subscriptions": "Alle Abonnements",
      "tab_my_subscriptions": "Meine Abonnements",
      "alert_access_code_redeem_code_used": "Angeforderter Zugangscode bereits verwendet",
      "label_full_access": "Voller Zugriff",
      "label_full_access_description": "Voller Zugriff auf alle Inhalte der Website",
      "label_limited_access": "Eingeschränkter Zugang",
      "label_limited_access_description": "Eingeschränkter Zugriff auf alle Inhalte der Website",
      "subscription_expires": "Abonnement läuft am {date} ab",
      "payment_details": "{title}: Zahlungsdetails",
      "no_subscriptions_text": "Es gibt keine aktiven Abonnements in der Liste.\n\nKomme später wieder",
      "redeem_access_code_title": "Lösen Sie Ihren Zugangscode ein",
      "button_back_to_my_tickets": "Zurück zu meinen Tickets",
      "list_subscriptions_empty": "Die Liste der verfügbaren Abonnements ist leer. \nKommen Sie später wieder",
      "button_subscription_keep": "Abonnement behalten",
      "cancel_subscription_modal_subtitle": "Ihr Abonnement wird zum Ende Ihres Abrechnungszeitraums am {date} gekündigt. \nSie können Ihre Meinung jederzeit vor diesem Datum ändern.",
      "cancel_subscription_modal_title": "Möchten Sie Ihr Abonnement wirklich kündigen?",
      "checkbox_apply_as_new_credit_card": "Kartendetails speichern",
      "renew_subscription_expires": "Das Abonnement ist wiederkehrend. \nNächstes Ladedatum {date}"
    },
    "videos": {
      "last_viewed": "Zuletzt angesehen",
      "user_interesting": "Du könntest interessiert sein",
      "description_no_items": "Dies ist der Ort für Ihre Lieblingsvideos.\nSpeichern, bewerten und teilen!",
      "title_no_items": "Einige Videos zu ausgewählten hinzufügen"
    },
    "clips": {
      "title_no_clips": "Du hast noch keine Clips.\nGehen Sie zum Video und erstellen Sie Ihren ersten Clip!",
      "title_no_highlights": "Sie haben noch keine Highlights.\nGehen Sie zum Video und setzen Sie Ihre ersten Highlights!",
      "title_no_items_with_selected_filter": "Mit diesem Filter wurden keine Artikel gefunden"
    },
    "auth_sign_in": {
      "welcome_message": "Willkommen zurück",
      "new_to": "Neu für",
      "auth_terms": "Indem Sie fortfahren, stimmen Sie unseren {terms_of_service} und {privacy_policy} zu",
      "sign_in_with_provider": "Bitte melden Sie sich mit Ihren {provider}-Anmeldeinformationen an"
    },
    "account_password": {
      "alert_password_update_success": "Ihr Passwort wurde erfolgreich geändert"
    },
    "league": {
      "all_rounds": "Alle Runden"
    },
    "events": {
      "event_start_date": "Die Veranstaltung beginnt am {date}",
      "failed_statistics": "Statistiken zu diesem Ereignis konnten nicht geladen werden. Bitte versuchen Sie es später erneut.",
      "modal_share_with_time": "Beginn um {time}",
      "modal_share_title": "Teile dieses Video",
      "tabs": {
        "event_tags": {
          "create_tag": "Tag erstellen",
          "title_no_tags_not_logged_in": "Es gibt noch keine Tags in der Liste, bitte melden Sie sich an, um neue Tags zu erstellen",
          "choose_player_color": "Geben Sie einen Spieler ein '",
          "choose_tag": "Tag-Typ auswählen",
          "make_tag_global": "Tag global machen",
          "name_the_tag": "Benennen Sie das Tag",
          "tag_creating_successfully_text": "Tag wurde erfolgreich erstellt",
          "title_enabled_tagging": "Es gibt keine Tags in der Liste",
          "update_tag": "Tag aktualisieren",
          "tag_updating_successfully_text": "Tag wurde erfolgreich aktualisiert",
          "filters": {
            "by_player_number": "Spieler '",
            "by_type": "Nach Typ",
            "clear_all_btn": "Alles löschen |\nAlles löschen ({count}) |\nAlles löschen ({count})",
            "no_tags": "Keine Tags entsprechen dem ausgewählten Filter",
            "popup_title": "Filter",
            "search_input_placeholder": "Suchen Sie nach bestimmten Tags",
            "show_results_btn": "Zeige Ergebnisse",
            "select_all": "Alle auswählen | \nAlle auswählen ({count}) | \nAlle auswählen ({count})",
            "by_team": "Nach Team"
          },
          "tag_deleting_successfully_text": "Tag wurde erfolgreich gelöscht",
          "is_external_content": "Dies ist externer Inhalt, Sie können kein Tag erstellen",
          "title_no_tags": "Es wurden noch keine Tags erstellt",
          "pause_all_btn": "Alles pausieren",
          "play_all_btn": "Alle wiedergeben",
          "choose_team": "Wählen Sie ein Team"
        },
        "event_videos": {
          "create_clip": "Clip erstellen",
          "title_no_clips_not_logged_in": "Es gibt noch keine Clips in der Liste, bitte melden Sie sich an, um neue Clips zu erstellen",
          "name_the_clip": "Benennen Sie den Clip",
          "clip_creating_successfully_text": "Ihr Clip ist bald fertig",
          "title_enabled_clipping": "Die Liste enthält keine Clips",
          "title_no_clips": "Es wurden noch keine Clips erstellt",
          "clip_deleted_successfully_text": "Der Clip wurde erfolgreich gelöscht",
          "rename_clip": "Clip umbenennen",
          "clip_updating_successfully_text": "Der Clip wurde erfolgreich aktualisiert"
        },
        "event_stats": {
          "empty_stats_data": "Die Statistikdaten sind leer",
          "game_board_title": "Spielstatistiken",
          "score_board_title": "Punktzahl"
        }
      },
      "viewing_conditions": "Wählen Sie Ihre Betrachtungsbedingungen",
      "appreciation": "Vielen Dank!",
      "success_purchased": "Sie haben {subscription} erfolgreich gekauft",
      "failed_purchased_message": "Bitte versuchen Sie es erneut oder geben Sie eine andere Karte ein.",
      "purchased_declined": "Die Zahlung wurde abgelehnt",
      "watching_event_by_text": "Sie sehen sich dieses Ereignis an",
      "failed_code_applied_message": "Bitte versuchen Sie es erneut oder geben Sie einen anderen Code ein.",
      "failed_code_applied_title": "Ungültiger Code",
      "success_code_applied": "{title} Code erfolgreich angewendet",
      "submit_reedem_code_input_placeholder": "Geben Sie hier Ihren Zugangscode ein",
      "failed_already_used_code": "Dieser Code wurde bereits verwendet",
      "event_will_start_in_a_few_min": "Die Veranstaltung startet in wenigen Minuten automatisch...",
      "game_video_login_message": "Bitte melden Sie sich an, um Spielvideos anzusehen",
      "tags_login_message": "Bitte melden Sie sich an, um Tags anzuzeigen",
      "success_purchased_secondary": "Ihr Kauf war erfolgreich",
      "time_till_kick_off": "Zeit bis zum Anpfiff:",
      "failed_purchased_message_2": "Bitte versuchen Sie es erneut oder verwenden Sie eine andere Zahlungsmethode."
    },
    "club": {
      "favorite_club_creating_successfully_text": "Ihr Club wurde erfolgreich ausgewählt"
    },
    "categories": {
      "no_following_category_for_logged_user": "Sie verfolgen im Moment nichts. \nKlicken Sie {here}, um über Ihre Lieblingsveranstaltungen auf dem Laufenden zu bleiben",
      "no_following_category_for_unlogged_user": "Bitte {login}, um über Ihre Lieblingsveranstaltungen auf dem Laufenden zu bleiben"
    }
  },
  "sport_types": {
    "americanFootball": "Amerikanischer Fußball",
    "beachvolleyball": "Beach-Volleyball",
    "fieldhockey": "Feldhockey",
    "icehockey": "Eishockey",
    "rollerhockey": "Rollhockey",
    "badminton": "Badminton",
    "baseball": "Baseball",
    "basketball": "Basketball",
    "bowling": "Bowling",
    "cricket": "Kricket",
    "floorball": "Unihockey",
    "futsal": "Futsal",
    "gymnastics": "Gymnastik",
    "handball": "Handball",
    "lacrosse": "Lacrosse",
    "netball": "Netzball",
    "other": "Sonstiges",
    "rugby": "Rugby",
    "snooker": "Snooker",
    "soccer": "Fußball",
    "softball": "Weicher Ball",
    "swimming": "Schwimmen",
    "tennis": "Tennis",
    "volleyball": "Volleyball",
    "waterpolo": "Wasser Polo",
    "wrestling": "Ringen",
    "football": "Fußball"
  },
  "baseFooter": {
    "rights": "Alle Rechte vorbehalten"
  },
  "footer_links": {
    "about_us": "Um",
    "faq": "FAQ",
    "privacy_policy": "Privatsphäre",
    "terms_of_service": "Bedingungen",
    "custom": "{custom}"
  },
  "countries": {
    "AD": "Andorra",
    "AE": "Vereinigte Arabische Emirate",
    "AF": "Afghanistan",
    "AG": "Antigua und Barbuda",
    "AI": "Anguilla",
    "AL": "Albanien",
    "AM": "Armenien",
    "AO": "Angola",
    "AR": "Argentinien",
    "AS": "Amerikanischen Samoa-Inseln",
    "AT": "Österreich",
    "AU": "Australien",
    "AW": "Aruba",
    "AZ": "Aserbaidschan",
    "BA": "Bosnien und Herzegowina",
    "BB": "Barbados",
    "BD": "Bangladesch",
    "BE": "Belgien",
    "BF": "Burkina Faso",
    "BG": "Bulgarien",
    "BH": "Bahrein",
    "BI": "Burundi",
    "BJ": "Benin",
    "BM": "Bermudas",
    "BO": "Bolivien",
    "BR": "Brasilien",
    "BS": "Bahamas",
    "BT": "Bhutan",
    "BW": "Botswana",
    "BY": "Weißrussland",
    "BZ": "Belize",
    "CA": "Kanada",
    "CF": "Zentralafrikanische Republik",
    "CG": "Kongo",
    "CH": "Schweiz",
    "CK": "Cookinseln",
    "CL": "Chile",
    "CM": "Kamerun",
    "CN": "China",
    "CO": "Kolumbien",
    "CR": "Costa Rica",
    "CU": "Kuba",
    "CX": "Weihnachtsinsel",
    "CY": "Zypern",
    "DE": "Deutschland",
    "DJ": "Dschibuti",
    "DK": "Dänemark",
    "DM": "Dominika",
    "DO": "Dominikanische Republik",
    "DZ": "Algerien",
    "EC": "Ecuador",
    "EE": "Estland",
    "EG": "Ägypten",
    "ER": "Eritrea",
    "ES": "Spanien",
    "ET": "Äthiopien",
    "FI": "Finnland",
    "FJ": "Fidschi",
    "FK": "Falkland Inseln",
    "FM": "Mikronesien",
    "FO": "Färöer Inseln",
    "FR": "Frankreich",
    "GA": "Gabun",
    "GD": "Grenada",
    "GE": "Georgia",
    "GF": "Französisch-Guayana",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GL": "Grönland",
    "GM": "Gambia",
    "GN": "Guinea",
    "GP": "Guadeloupe",
    "GQ": "Äquatorialguinea",
    "GR": "Griechenland",
    "GS": "Süd-Georgien und die südlichen Sandwich-Inseln",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HN": "Honduras",
    "HR": "Kroatien",
    "HT": "Haiti",
    "HU": "Ungarn",
    "ID": "Indonesien",
    "IE": "Irland",
    "IL": "Israel",
    "IN": "Indien",
    "IO": "Britisches Territorium des Indischen Ozeans",
    "IQ": "Irak",
    "IR": "Iran",
    "IS": "Island",
    "IT": "Italien",
    "JM": "Jamaika",
    "JO": "Jordanien",
    "JP": "Japan",
    "KE": "Kenia",
    "KG": "Kirgistan",
    "KH": "Kambodscha",
    "KI": "Kiribati",
    "KM": "Komoren",
    "KW": "Kuwait",
    "KY": "Cayman Inseln",
    "KZ": "Kasachstan",
    "LB": "Libanon",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Liberia",
    "LS": "Lesotho",
    "LT": "Litauen",
    "LU": "Luxemburg",
    "LV": "Lettland",
    "LY": "Libyen",
    "MA": "Marokko",
    "MC": "Monaco",
    "MD": "Moldawien",
    "ME": "Montenegro",
    "MG": "Madagaskar",
    "MH": "Marshallinseln",
    "ML": "Mali",
    "MM": "Burma",
    "MN": "Mongolei",
    "MP": "Nördliche Marianneninseln",
    "MQ": "Martinique",
    "MR": "Mauretanien",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Mauritius",
    "MV": "Malediven",
    "MW": "Malawi",
    "MX": "Mexiko",
    "MY": "Malaysia",
    "NA": "Namibia",
    "NC": "Neu-Kaledonien",
    "NE": "Niger",
    "NF": "Norfolkinsel",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Niederlande",
    "NO": "Norwegen",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Neuseeland",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "Französisch Polynesien",
    "PG": "Papua Neu-Guinea",
    "PH": "Philippinen",
    "PK": "Pakistan",
    "PL": "Polen",
    "PR": "Puerto Rico",
    "PT": "Portugal",
    "PW": "Palau",
    "PY": "Paraguay",
    "QA": "Katar",
    "RO": "Rumänien",
    "RS": "Serbien",
    "RW": "Ruanda",
    "SA": "Saudi-Arabien",
    "SB": "Salomon-Inseln",
    "SC": "Seychellen",
    "SD": "Sudan",
    "SE": "Schweden",
    "SG": "Singapur",
    "SI": "Slowenien",
    "SK": "Slowakei",
    "SL": "Sierra Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SV": "El Salvador",
    "TC": "Turks- und Caicosinseln",
    "TD": "Tschad",
    "TG": "Gehen",
    "TH": "Thailand",
    "TJ": "Tadschikistan",
    "TK": "Tokelau",
    "TM": "Turkmenistan",
    "TN": "Tunesien",
    "TO": "Tonga",
    "TR": "Truthahn",
    "TT": "Trinidad und Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "UA": "Ukraine",
    "UG": "Uganda",
    "UY": "Uruguay",
    "UZ": "Usbekistan",
    "VE": "Venezuela",
    "VU": "Vanuatu",
    "WF": "Wallis und Futuna",
    "WS": "Samoa",
    "YE": "Jemen",
    "YT": "Mayotte",
    "ZA": "Südafrika",
    "ZM": "Sambia",
    "ZW": "Zimbabwe",
    "BN": "Brunei",
    "CV": "Kap Verde",
    "CW": "Curacao",
    "HK": "Sonderverwaltungszone Hongkong",
    "KP": "Nord Korea",
    "KR": "Südkorea",
    "LA": "Laos",
    "MO": "Sonderverwaltungszone Macau China",
    "PS": "Palästinensisches Gebiet",
    "RE": "Wiedervereinigung",
    "RU": "Russland",
    "SY": "Syrien",
    "TZ": "Tansania",
    "GB": "Vereinigtes Königreich",
    "US": "Vereinigte Staaten",
    "VG": "Britische Jungferninseln",
    "VI": "US Jungferninseln",
    "VN": "Vietnam",
    "CD": "Kongo, Dem.\nRepublik (Zaire)",
    "AX": "Åland-Inseln",
    "BQ": "Karibische Niederlande",
    "EA": "Ceuta und Melilla",
    "EH": "Westsahara",
    "GG": "Guernsey",
    "IC": "Kanarische Inseln",
    "IM": "Isle of Man",
    "JE": "Jersey",
    "MZ": "Mosambik",
    "PN": "Pitcairninseln",
    "SO": "Somalia",
    "SS": "Südsudan",
    "SX": "Sint Maarten",
    "TA": "Tristan da Cunha",
    "TF": "Südfranzösische Territorien",
    "VA": "Vatikanstadt",
    "XK": "Kosovo",
    "AC": "Ascension-Insel",
    "CZ": "Tschechien",
    "CC": "Kokosinseln (Keelinginseln).",
    "TL": "Timor-Leste",
    "CI": "Elfenbeinküste",
    "MK": "Nordmazedonien",
    "SR": "Surinam",
    "SZ": "Eswatini",
    "DG": "Diego Garcia"
  },
  "errors": {
    "expired_session_error": "Deine Sitzung ist abgelaufen. \nBitte melden Sie sich erneut an.",
    "vod_files_not_found": "Hoppla! \nVideodateien nicht gefunden",
    "vod_playback_failed": "Hoppla! \nWiedergabe während des VOD-Streams fehlgeschlagen",
    "could_not_find_event": "Entschuldigung, wir konnten die von Ihnen gesuchte Veranstaltung nicht finden.",
    "could_not_find_page": "Entschuldigung, wir konnten die gesuchte Seite nicht finden.",
    "could_not_find_team": "Entschuldigung, wir konnten das gesuchte Team nicht finden.",
    "event_not_found": "Veranstaltung nicht gefunden",
    "page_not_found": "Seite nicht gefunden",
    "team_not_found": "Mannschaft nicht gefunden",
    "could_not_find_results": "Leider konnten wir für diese Suche keine Ergebnisse finden.",
    "ERR400CL11": "Clip konnte nicht erstellt werden",
    "ERR400CM11": "Bezahlung fehlgeschlagen",
    "ERR400CM12": "Bestellung aufgeben fehlgeschlagen",
    "ERR400CM13": "Die Erfassung der Zahlung ist fehlgeschlagen",
    "ERR400CM14": "Das Speichern der Händlerinformationen im Abonnement ist fehlgeschlagen",
    "ERR400CM15": "Bitte überprüfen Sie Ihre Kreditkarteninformationen",
    "ERR400CM16": "Ungültiger Plattformwert angegeben",
    "ERR400GN00": "Ungültige Anforderung",
    "ERR400GN11": "Ungültige ID angegeben",
    "ERR400GN12": "Nicht aktualisierbare Felder vorhanden",
    "ERR400ID11": "Ungültige Identität angegeben",
    "ERR400ID12": "Ungültiges Identitätselement angegeben",
    "ERR400ID13": "Die Identität kann nicht zur Clubliste hinzugefügt werden",
    "ERR400ID14": "Ungültiges Paar aus Identität und Identitätselement angegeben",
    "ERR400TG11": "Tag konnte nicht erstellt werden",
    "ERR400UR11": "Im Profil sind Vorname und Nachname erforderlich",
    "ERR400UR12": "E-Mail ist erforderlich",
    "ERR400UR13": "Der Benutzer verfügt bereits über eine hinterlegte Kreditkarte",
    "ERR400UR14": "Bitte füllen Sie die Felder „Vorname“, „Nachname“ und „E-Mail“ in Ihrem Profil aus, bevor Sie eine Kreditkarte speichern",
    "ERR400UR17": "Der Benutzer hat keine Kreditkarte zum Bearbeiten",
    "ERR400UR18": "Das Abonnement ist bereits gekündigt",
    "ERR400UR19": "Das Abonnement ist nicht aktiv",
    "ERR400UR20": "Benutzername und Passwort sind erforderlich",
    "ERR400UR21": "Konto ist nicht aktiv",
    "ERR401AT11": "Token ist erforderlich",
    "ERR401AT12": "Ungültiges Passwort-Reset-Token",
    "ERR401AT13": "Ungültiges Aktualisierungstoken",
    "ERR401AT14": "Unbekannter Betreff-Emittent",
    "ERR401AT15": "Nicht unterstützter Betreff-Token-Typ",
    "ERR401AT16": "Ungültiges Token",
    "ERR401AT17": "Abgelaufenes Token",
    "ERR401AT18": "Es konnte keine E-Mail vom Token abgerufen werden",
    "ERR401AT19": "Benutzer nicht gefunden",
    "ERR401GN00": "Nicht autorisiert",
    "ERR401UR11": "Der Benutzername oder das Passwort ist falsch. \nBitte überprüfen Sie Ihre Anmeldedaten.",
    "ERR402GN00": "Bezahlung erforderlich",
    "ERR403GN00": "Verboten",
    "ERR403TG11": "Der Benutzer kann globale Tags nicht aktualisieren",
    "ERR403TG12": "Der Benutzer kann keine globalen Tags erstellen",
    "ERR403TG13": "Der Benutzer kann die Tags nicht löschen, die nicht von ihm erstellt wurden",
    "ERR403TG14": "Der Benutzer kann keine globalen Tags löschen",
    "ERR404CM11": "Es können keine Abonnements abgerufen werden",
    "ERR404CM12": "Zugangscode nicht gefunden",
    "ERR404EV11": "Es können keine Ereignisinformationen abgerufen werden",
    "ERR404EV12": "Veranstaltung oder VOD nicht gefunden",
    "ERR404GN00": "Nicht gefunden",
    "ERR404ID00": "Identität nicht gefunden",
    "ERR404ID11": "Die Identität konnte nicht abgerufen werden",
    "ERR404PR00": "Produkt nicht gefunden",
    "ERR404PR11": "PPV-Informationen können nicht abgerufen werden",
    "ERR404TG00": "Tag nicht gefunden",
    "ERR404TM00": "Team nicht gefunden",
    "ERR404UR00": "Benutzer nicht gefunden",
    "ERR404UR11": "Bestellung wurde nicht gefunden",
    "ERR404UR12": "Das Abonnement wurde nicht gefunden",
    "ERR404UR13": "Benutzer existiert nicht",
    "ERR404UR14": "Profil existiert nicht",
    "ERR404UR15": "Für diesen Benutzer wurde keine Karte gefunden",
    "ERR404UR16": "Video nicht auf der Watchlist gefunden",
    "ERR404VD11": "Vod konnte nicht abgerufen werden",
    "ERR409CM11": "Zugangscode bereits verwendet",
    "ERR409GN00": "Konflikt",
    "ERR409PR12": "Produkt bereits gekauft",
    "ERR409UR11": "Club ist bereits hinzugefügt",
    "ERR409UR12": "Benutzer bereits registriert",
    "ERR422GN00": "Nicht verarbeitbarer Inhalt",
    "ERR451GN00": "Aus rechtlichen Gründen nicht verfügbar",
    "ERR500GN00": "interner Serverfehler",
    "ERR500NT12": "Das Senden der E-Mail zum Zurücksetzen des Passworts ist fehlgeschlagen",
    "ERR501GN00": "Nicht implementiert",
    "ERR502GN00": "Schlechtes Gateway",
    "ERR503GN00": "Dienst nicht verfügbar",
    "ERR504GN00": "Gateway-Timeout",
    "could_not_find_league": "Leider konnten wir die von Ihnen gesuchte Liga nicht finden.",
    "default_error_message": "Es ist ein Serverfehler aufgetreten. \nBitte versuchen Sie es später noch einmal",
    "device_limit_exceeded": "Gerätelimit überschritten!",
    "league_not_found": "Liga nicht gefunden",
    "vod_corrupted_files": "Das Video konnte nicht geladen werden",
    "passwords_dont_match": "Passwörter stimmen nicht überein",
    "incomplete_cvc": "Der Sicherheitscode Ihrer Karte ist unvollständig!",
    "incomplete_expiry": "Das Ablaufdatum Ihrer Karte ist unvollständig!",
    "incomplete_number": "Ihre Kartennummer ist unvollständig!",
    "invalid_expiry_month_past": "Das Ablaufjahr Ihrer Karte liegt in der Vergangenheit!",
    "invalid_expiry_year": "Das Ablaufjahr Ihrer Karte ist ungültig!",
    "invalid_expiry_year_past": "Das Ablaufjahr Ihrer Karte liegt in der Vergangenheit!",
    "invalid_number": "Ihre Kartennummer ist ungültig!",
    "popup_closed": "Popup wurde geschlossen",
    "login_failed": "Fehler bei der Anmeldung",
    "ERR400UR24": "Das alte Passwort ist falsch. \nBitte überprüfen Sie Ihren Ausweis",
    "live_stream_error_message": "Hoppla! Es sieht so aus, als gäbe es eine kleine Verzögerung. \nUnser Team arbeitet aktiv daran, das Problem so schnell wie möglich zu lösen. \nVielen Dank für Ihre Geduld!"
  },
  "tags": {
    "baseball_bottom_1st": "unten 1",
    "baseball_bottom_2nd": "unten 2",
    "baseball_double": "doppelt",
    "baseball_double_play": "doppeltes Spiel",
    "baseball_fly_out": "ausfliegen",
    "baseball_ground_out": "erden",
    "baseball_hit": "Schlag",
    "baseball_home_run": "Home Run",
    "baseball_popout": "herausspringen",
    "baseball_run": "laufen",
    "baseball_single": "einzel",
    "baseball_stolen_base": "gestohlene Basis",
    "baseball_strikeout": "durchstreichen",
    "baseball_substitution": "Auswechslung",
    "baseball_top_1st": "oben 1",
    "baseball_top_2nd": "oben 2",
    "baseball_triple_play": "dreifaches Spiel",
    "baseball_tripple": "verdreifachen",
    "baseball_walk": "gehen",
    "basketball_alley_oop": "Gasse oop",
    "basketball_assist": "helfen",
    "basketball_big_play": "großes Spiel",
    "basketball_block": "Block",
    "basketball_dunk": "eintauchen",
    "basketball_momentym": "Schwung",
    "basketball_money_time": "Geldzeit",
    "basketball_substitution": "Auswechslung",
    "basketball_three_point": "Drei Punkt",
    "beach_volleyball_dig": "graben",
    "beach_volleyball_double": "doppelt",
    "beach_volleyball_free_ball": "freie Kugel",
    "beach_volleyball_match_point": "Matchball",
    "beach_volleyball_mishit": "Mist",
    "beach_volleyball_serve": "Aufschlag",
    "beach_volleyball_set_point": "Sollwert",
    "beach_volleyball_shank": "Schaft",
    "beach_volleyball_side_out": "Seite aus",
    "beach_volleyball_spike": "Spitze",
    "beach_volleyball_substitution": "Auswechslung",
    "field_ball_loss": "Ballverlust",
    "field_ball_possesion": "Ballbesitz",
    "field_circle_penetration": "Kreisdurchdringung",
    "field_hockey_4v4": "4v4",
    "field_hockey_big_hit": "großer Schlag",
    "field_hockey_fight": "Streit",
    "field_hockey_goal": "Ziel",
    "field_hockey_other": "andere",
    "field_hockey_power_play": "Machtspiel",
    "field_hockey_save": "speichern",
    "field_hockey_shot": "Schuss",
    "field_hockey_substitution": "Auswechslung",
    "field_interception": "Abfangen",
    "field_penalty_corner": "Strafecke",
    "field_press_fp_hc": "Drücken Sie",
    "field_time_penalty": "Zeitstrafe",
    "field_turn_over": "umdrehen",
    "football_big_catch": "großer Fang",
    "football_big_hit": "großer Schlag",
    "football_big_run": "großer Lauf",
    "football_field_goal": "Feldziel",
    "football_kick_return": "Tritt zurück",
    "football_kickoff": "beginnen",
    "football_punt_return": "Punt zurück",
    "football_substitution": "Auswechslung",
    "football_touch_down": "landen",
    "futsal_corner_kick": "Eckstoß",
    "futsal_foul": "Foul",
    "futsal_free_kick": "Freistoß",
    "futsal_goal": "Ziel",
    "futsal_header": "Header",
    "futsal_penalty": "Strafe",
    "futsal_redcard": "rote Karte",
    "futsal_save": "speichern",
    "futsal_shot": "Schuss",
    "futsal_shot_on_target": "aufs Ziel geschossen",
    "futsal_substitution": "Auswechslung",
    "futsal_yellowcard": "gelbe Karte",
    "general_bad": "schlecht",
    "general_drill": "bohren",
    "general_good": "Gut",
    "gymnastic_wow": "Wow",
    "handball_foul": "Foul",
    "handball_goal": "Ziel",
    "handball_penalty_shot": "Strafschuss",
    "handball_red_card": "rote Karte",
    "handball_save": "speichern",
    "handball_shot_on_goal": "aufs Tor geschossen",
    "handball_substitution": "Auswechslung",
    "handball_suspension": "Suspension",
    "handball_yellow_card": "gelbe Karte",
    "hockey_4v4": "4v4",
    "hockey_big_hit": "großer Schlag",
    "hockey_fight": "Streit",
    "hockey_goal": "Ziel",
    "hockey_power_play": "Machtspiel",
    "hockey_save": "speichern",
    "hockey_shot": "Schuss",
    "hockey_substitution": "Auswechslung",
    "lacrosse_clear": "klar",
    "lacrosse_face_off": "Gesicht ab",
    "lacrosse_goal": "Ziel",
    "lacrosse_ground_ball": "Bodenkugel",
    "lacrosse_man_up": "Mann auf",
    "lacrosse_penalty": "Strafe",
    "lacrosse_save": "speichern",
    "lacrosse_shot": "Schuss",
    "lacrosse_substitution": "Auswechslung",
    "other_wow": "andere wow",
    "roller_ball_loss": "Ballverlust",
    "roller_ball_possesion": "Ballbesitz",
    "roller_circle_penetration": "Kreisdurchdringung",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_big_hit": "großer Schlag",
    "roller_hockey_fight": "Streit",
    "roller_hockey_goal": "Ziel",
    "roller_hockey_other": "andere",
    "roller_hockey_power_play": "Machtspiel",
    "roller_hockey_save": "speichern",
    "roller_hockey_shot": "Schuss",
    "roller_hockey_substitution": "Auswechslung",
    "roller_interception": "Abfangen",
    "roller_penalty_corner": "Ecke",
    "roller_press_fp_hc": "Drücken Sie",
    "roller_time_penalty": "Zeitstrafe",
    "roller_turn_over": "umdrehen",
    "rugby_break_down": "abbauen",
    "rugby_conversion": "Wandlung",
    "rugby_drop_goal": "Ziel fallen lassen",
    "rugby_garry_owen": "Garry Owen",
    "rugby_grubber": "Grubber",
    "rugby_knock_on": "klopft an",
    "rugby_maul": "Maul",
    "rugby_penalty_try": "Strafversuch",
    "rugby_substitution": "Auswechslung",
    "soccer_corner_kick": "Eckstoß",
    "soccer_foul": "Foul",
    "soccer_free_kick": "Freistoß",
    "soccer_goal": "Ziel",
    "soccer_header": "Header",
    "soccer_penalty": "Strafe",
    "soccer_redcard": "rote Karte",
    "soccer_save": "speichern",
    "soccer_shot": "Schuss",
    "soccer_shot_on_target": "aufs Ziel geschossen",
    "soccer_substitution": "Auswechslung",
    "soccer_yellowcard": "gelbe Karte",
    "volleyball_dig": "graben",
    "volleyball_double": "doppelt",
    "volleyball_free_ball": "freie Kugel",
    "volleyball_match_point": "Matchball",
    "volleyball_mishit": "Mist",
    "volleyball_serve": "Aufschlag",
    "volleyball_set_point": "Sollwert",
    "volleyball_shank": "Schaft",
    "volleyball_side_out": "Seite aus",
    "volleyball_spike": "Spitze",
    "volleyball_substitution": "Auswechslung",
    "wrestling_wow": "Wow"
  }
}
